<template>
  <div class="businesses p-8">
    <div class="mx-auto max-w-5xl space-y-6">
      <div class="flex justify-between">
        <h1 class="text-3xl font-semibold ">
          Businesses
        </h1>
        <router-link to="/businesses/add">
          <svg
            class="h-10 w-10 text-blue-600 hover:text-blue-700"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </router-link>
      </div>
      <div class="shadow overflow-hidden border border-gray-200">
        <table class="w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                ID
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Business Name
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Billing
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Status
              </th>
              <th scope="col" class="relative px-6 py-3">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="doc in documents" :key="doc.id" class="hover:bg-gray-50">
              <td
                @click="goTo(`/business/${doc.id}/settings`)"
                class="p-4 whitespace-nowrap text-sm font-medium text-gray-700 cursor-pointer"
              >
                {{ doc.id }}
              </td>
              <td
                @click="goTo(`/business/${doc.id}/settings`)"
                class="p-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer"
              >
                {{ doc.business_name }}
              </td>
              <td
                @click="goTo(`/business/${doc.id}/settings`)"
                class="p-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer"
              >
                {{ doc.business_type }}
              </td>
              <td
                @click="goTo(`/business/${doc.id}/settings`)"
                class="p-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer"
              >
                {{ doc.business_status }}
              </td>
              <td class="p-4 text-sm text-right">
                <span
                  v-if="!isPendingReload && user.claims.business_id !== doc.id"
                  @click="masquerade(`${doc.id}`)"
                  class="px-5 py-1 bg-blue-500 rounded-full text-sm font-medium text-white cursor-pointer"
                >
                  Masquerade
                </span>
                <span
                  v-if="!isPendingReload && user.claims.business_id == doc.id"
                  @click="unmasquerade()"
                  class="px-5 py-1 bg-blue-500 rounded-full text-sm font-medium text-white cursor-pointer"
                >
                  Un-Masquerade
                </span>
                <span
                  v-if="isPendingReload"
                  class="px-5 py-1 text-sm font-medium"
                >
                  Please Reload
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { auth, functions } from "@/firebaseConfig";
import getCollection from "@/composables/getCollection";

export default {
  props: ["user"],
  setup(props) {
    const isPendingReload = ref(false);
    const router = useRouter();

    const { error, documents } = getCollection("businesses");

    const goTo = (url) => {
      router.push(url);
    };

    const setCustomUserClaims = functions.httpsCallable("setCustomUserClaims");

    const masquerade = async (id) => {
      isPendingReload.value = true;
      await setCustomUserClaims({
        business_id: id,
        uid: props.user.uid,
        user_role: "masquerade",
      }).then(() => {
        auth.currentUser.getIdToken(true);
      });
    };

    const unmasquerade = async () => {
      isPendingReload.value = true;
      await setCustomUserClaims({
        business_id: null,
        uid: props.user.uid,
        user_role: "superadmin",
      }).then(() => {
        auth.currentUser.getIdToken(true);
      });
    };

    return {
      isPendingReload,
      error,
      documents,
      goTo,
      masquerade,
      unmasquerade,
    };
  },
};
</script>
